
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import {
  ICustomerPriceTag,
  ICustomerPriceTagCreateRequest,
  ICustomerPriceTagRequest,
  ICustomerPriceTagUpdateRequest
} from "@/types/customer_price_tag";
import { CustomerPriceTagRequest } from "@/models/customer_price_tag";
import { ICustomer, ICustomerListRequest } from "@/types/customer";
import { IPriceTag } from "@/types/price_tag";

@Component({})
export default class extends Vue {
  //customer_price_tag
  @Action("customer_price_tag/adminGet")
  public getCustomerPriceTag!: (customer_price_tag_id: number) => void;

  @Action("customer_price_tag/adminCreate")
  public create!: (params: ICustomerPriceTagCreateRequest) => boolean;

  @Action("customer_price_tag/adminUpdate")
  public update!: (data: {
    customer_price_tag_id: number;
    params: ICustomerPriceTagUpdateRequest;
  }) => boolean;

  @Action("customer_price_tag/adminDelete")
  public deleteCustomerPriceTag!: (customer_price_tag_id: number) => boolean;

  @Action("customer_price_tag/adminIsUniqueCustomerPN")
  public isUniqueCustomerPN!: (params: ICustomerPriceTagRequest) => boolean;

  @Getter("customer_price_tag/single")
  public customer_price_tag!: ICustomerPriceTag;

  @Mutation("customer_price_tag/clear")
  public clearCustomerPriceTag!: () => void;

  //price_tag
  @Action("price_tag/adminGet")
  public getPriceTag!: (price_tag_id: number) => void;

  @Getter("price_tag/single")
  public price_tag!: IPriceTag;

  @Mutation("price_tag/clear")
  public clearPriceTag!: () => void;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => boolean;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //パラメータ定義
  public params: ICustomerPriceTagRequest = new CustomerPriceTagRequest();

  //変数定義
  public customer_price_tag_id = 0;
  public price_tag_id = 0;
  public price_tag_name = "";
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public customer_pn_errors: string[] = [];

  //ルール設定
  public rules = {
    customer_pn: [(v: string) => !!v || "顧客品番は必須です"],
    customer_id: [(v: number) => !!v || "顧客は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearPriceTag();
    this.clearCustomer();
    this.clearCustomerPriceTag();

    this.price_tag_id = Number(this.$route.params.price_tag_id) || 0;
    this.customer_price_tag_id =
      Number(this.$route.params.customer_price_tag_id) || 0;

    await this.getCustomers({ per_page: 0 });

    //新規時
    if (this.price_tag_id) {
      await this.getPriceTag(this.price_tag_id);
      this.setCreateDefault();
    }

    // 編集時
    if (this.customer_price_tag_id) {
      await this.getCustomerPriceTag(this.customer_price_tag_id);
      this.setUpdateDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  //新規時デフォルト値をセットする;
  private async setCreateDefault() {
    this.params.price_tag_id = this.price_tag.id;
    this.price_tag_name = this.price_tag.name;
  }

  //--------
  //更新時デフォルト値をセットする;
  private async setUpdateDefault() {
    this.params.createFromCustomerPriceTag(this.customer_price_tag);
    this.price_tag_name = this.customer_price_tag.price_tag_name;
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.customer_price_tag_id) {
      result = await this.update({
        customer_price_tag_id: this.customer_price_tag_id,
        params: { ...this.params, id: this.customer_price_tag.id }
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteCustomerPriceTag(this.customer_price_tag_id)) {
      this.$router.go(-1);
    }
  }

  // 顧客品番の二重登録チェック
  public async customer_pn_validate() {
    // 登録時
    if (
      !(await this.isUniqueCustomerPN(this.params)) &&
      !this.customer_price_tag.customer_pn
    ) {
      return (this.customer_pn_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueCustomerPN(this.params)) &&
      this.customer_price_tag.customer_pn != this.params.customer_pn
    ) {
      return (this.customer_pn_errors = ["既に使用されています"]);
    }

    return (this.customer_pn_errors = []);
  }
}
